.section.home-news {
    z-index: 0;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;
    @include media-tablet-down() {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .wrapper {
        @include media-tablet-down() {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
        }
            
    }

    .news-border {
        background-color: #353535;
        padding: 55px 20px 20px;
        height: 605px;
        overflow: hidden;
        position: relative;
        @include media-desktop-down() {
            height: calc(36vw - 20px);
        }
        @include media-tablet-down() {
            height: calc(195vw + 50px);
        }
        &.opened {
            height: auto;
        }

        &:before {
            content: " ";
            display: block;
            position: absolute;
            width: calc(100% + 40px);
            height: 24px;
            top: 0;
            left: 0;
            background: $bgcolor;
        }
        
        

        > h1 {
            background: #232323;
            color: $highlight;
            text-transform: uppercase;
            font-size: 21px;
            
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            padding: 10px 20px;
            font-weight: bold;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;

            @include media-tablet-down() {
                width: 100%;
            }
        }
    }

    .news-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        .news-box {
            width: calc(33.333% - 20px);
            padding: 10px;
            height: 300px;
            transition: .2s ease-in;

            @include media-desktop-down() {
                width: calc(50% - 20px);
                height: 36vw;
            }
            @include media-tablet-down() {
                width: calc(100% - 20px);
                height: 60vw;
                margin-bottom: 5vw;
            }
            img {
                width: 100%;
                height: auto;
            }
            
            .box-content {
                margin-top: 10px;
                display: block;
                padding-right:80px;
                position: relative;
                @include media-tablet-down {
                    padding-right: 40px;
                    
                }
            }

            .more-button {
                width: 55px;
                height: 55px;
                position: absolute;
                top: 0;
                right: 0;
                background-size: cover;
                background-position: center;
                @include backgroundImage('../images/more-normal.png');
                display: block;
                @include media-tablet-down {
                    width: 30px;
                    height: 30px;
                }

                &:after {
                    transform: scale(1.1);
                    transition: .2s ease-in;
                    content: " ";
                    width: 55px;
                    height: 55px;
                    position: absolute;
                    top: 0;
                    right: 0;
                    background-size: cover;
                    background-position: center;
                    @include backgroundImage('../images/more-hover.png');
                    display: block;
                    opacity: 0;
                    @include media-tablet-down {
                        width: 30px;
                        height: 30px;
                    }
                }

            }

            h3 {
                color: $highlight;   
                font-size: 20px;
                text-transform: uppercase;
                margin-bottom: 5px;
                @include media-tablet-down() {
                    font-size: 10px;
                }
            }
            
            h2 {
                color: $white;
                font-size: 20px;
                line-height: 120%;
                display: block;
                max-height: 51px;
                overflow: hidden;

                @include media-tablet-down() {
                    font-size: 15px;
                }
            }

            &:hover {
                background-color: rgba($highlight, 0.2);
                
                .more-button:after {
                    opacity: 1;
                    transform: scale(1.0);
                }
            }
        }
    }

    .show-more-button {
        margin: 20px auto 0;
        display: block;
        background-color: #232323;
        border: 0;
        font-size: 14px;
        color: $white;
        text-transform: uppercase;
        padding: 15px;
        padding-right: 50px;
        @include backgroundImage('../images/main-carousel-arrow-right.png');
        background-size: contain;
        background-position: right center;
        transition: .2s ease-in;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 0 5px $highlight;
        }

        @include media-tablet-down() {
            margin-top: 0;
            width: 100%;
            margin-bottom: 0;
            @include backgroundImage('../images/more-normal.png');
            background-size: contain;
            background-position: right center;
            background-color: $bgcolor;
        }
    }
}
