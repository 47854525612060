.carousel.secondary-big {
    overflow: hidden;
    max-width: 1240px;
    margin: -50px auto 0;
    @include media-tablet-down {
        margin: 0 auto;
    }

    .carousel-dots {
        display: none;
    }

    @include media-tablet-down {
        .prev-arrow {
            left: -12px;
        }
    
        .next-arrow {
            right: -12px;
        }
    }

    .carousel-sections {
        height: auto;

        .carousel-section {
            width: 1240px;
            padding-top: 61%;
            position: relative;



            .content {
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top: 80px;
                left: 80px;
                width: calc(100% - 160px);
                height: calc(100% - 160px);
                @include media-tablet-down {
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: 2.0s ease-in opacity;
                }

                h2 {
                    position: absolute;
                    bottom: -50px;
                    display: block;
                    width: 100%;
                    height: 40px;
                    transition: .2s ease-out;
                    font-size: 20px;
                }
            }

            &.active .content {
                iframe {
                    opacity: 1;
                }
            }
        }
    }
}

.carousel.secondary-thumbnails {
    margin-top: -42px;

    @include media-desktop-up {
        max-width: 1420px;
        margin-left: auto;
        margin-right: auto;
    }
    @include media-tablet-down {
        display: none;
    }

    .prev-arrow {
        displaY: none;
    }

    .next-arrow {
        display: none;
    }

    .carousel-dots {
        display: none;
    }

    .carousel-sections {
        height: auto; 
    }

    .carousel-section {
        min-width: 20%;
        max-width: 20vw;
        width: 325px;
        height: 170px;
        position: relative;
        border-radius: 0;
        @include media-desktop-down {
            min-width: 30%;
            max-width: 30vw;
        }

        @include media-tablet-down {
            min-width: 85%;
            max-width: 85vw;
        }


        &.active .content {
            box-shadow: 0 0 0 5px rgba($highlight, 1);
        }

        .content {
            position: absolute;
            top: 10px;
            left: 5px;
            bottom: 10px;
            right: 5px;
            background: $white;
            transition: .3s ease-in;
            box-shadow: 0 0 0 0 rgba($highlight, 0);
            button {
                width: 100%;
                height: 100%;
                display: block;
                background-size: cover;
                border: 0;
                background-position: center;
            }
        }
    }
    
}