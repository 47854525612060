.page-about {
    display: block;
    width: 100%;
    padding-top: 1px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin-bottom: -25px;
    @include media-desktop-down() {
        margin-bottom: -25px;
    }
    @include media-tablet-down() {
        margin-bottom: 0;
    }
    @include media-phone-only {
        padding: 0;
    }

    .section {
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
        @include media-phone-only {
            margin: 0;

            &:first-child .wrapper,
            &:nth-child(even) .wrapper {
                background-color: $bgcolor;
            }
        }


        .wrapper {
            box-sizing: border-box;
            width: 1200px;
            padding: 0;
            justify-content: flex-start;
            background-color: #232323;
            @include media-desktop-down() {
                width: 100%;
            }
            @include media-phone-only {
                max-width: 100%;
            }
    

            h1 {
                font-size: 36px;
                display: block;
                text-align: center;
                width: 100%;
                color: $highlight;
                padding: 15px;
                font-weight: bold;
                @include media-phone-only {
                    font-size: 25px;
                    padding-right: 0;
                    padding-left: 0;
                }
            }

            h2, p {
                font-size: 21px;
                line-height: 120%;
                margin-bottom: 1em;
                @include media-tablet-down() {
                    font-size: 15px;
                }
            }

            h2 {
                color: $highlight;
            }

            p {
                color: $white;
                a {
                    color: $white;
                }
            }

            img {
                width: 100%;
                height: auto;
            }

            &.text-wrapper {
                padding: 45px 150px;
                @include media-desktop-down() {
                    padding: 30px;
                }

                @include media-phone-only {
                    padding: 20px;
                }                
            }
        }

        .chicken {
            position: absolute;
            width: 650px;
            height: 420px;
            bottom: -30px;
            right: 20px;
            @include backgroundImage("../images/chicken.png");
            z-index: 10;
            background-size: contain;
            background-position: right bottom;

            @include media-desktop-up {
                right: calc(50% - 620px);
                bottom: -30px;
            }

            @include media-desktop-down() {
                height: 300px;
                right: -20px;
            }
            @include media-tablet-down() {
                right: 0;
                height: calc(100% - 10px);
                bottom: 0;
            }
            @include media-phone-only {
                display: none;
            }

        }
    }
}