.page-games {
    display: block;
    width: 100%;
    padding-top: 1px;
    position: relative;
    z-index: 1;
    overflow: hidden;

    .section {
        margin-top: 30px;
        margin-bottom: 30px;
        position: relative;
        @include media-phone-only {
            margin: 0;

            &:first-child .wrapper,
            &:nth-child(even) .wrapper {
                background-color: $bgcolor;
            }
        }

        .wrapper {
            box-sizing: border-box;


            
        }

        .game-box {
            width: 1377px;
            max-width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 36px;
            background-color: #232323;

            .image {
                width: calc(55%);
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .content {
                width: calc(45%);
                padding: 10px 40px;

                h1 {
                    text-transform: uppercase;
                    font-size: 23px;
                    color: $highlight;
                    font-weight: bold;
                    margin-bottom: 20px;
                }

                .release-date {
                    color: $white;
                    font-size: 21px;
                    text-transform: uppercase;
                    margin-bottom: 20px;
                }
                .description {
                    font-size: 18px;
                    color: $white;
                }

                .button {
                    color: $white;
                    font-size: 14px;                    
                    padding: 13px;
                    background-color: $bgcolor;
                    display: inline-block;
                    text-transform: uppercase;
                    margin-top: 40px;
                    padding-right: 40px;
                    @include backgroundImage('../images/more-normal.png');
                    background-size: contain;
                    background-position: right center;
                }
            }
        }
    }

}