.carousel-main {
    padding-bottom: 45px;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 calc(-50vw - 45px) 0 50vw rgba($black, 0.5);
    
    @include media-tablet-down() {
        padding-bottom: 25px;
    }

    .carousel {
        .carousel-dots {
            bottom: -40px;
        }

        .prev-arrow {
            left: -12px;
        }

        .next-arrow {
            right: -12px;
        }

        .carousel-sections {
            height: auto;
            transition: 650ms transform ease-in;
            > div {
                border-radius: 0;
                padding-top: 44%;
                position: relative;
                .content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    &.wild      {@include backgroundImage('../images/carousel-main/wild.jpg');} 
                    &.cphive    {@include backgroundImage('../images/carousel-main/cphive.webp');} 
                    &.retro     {@include backgroundImage('../images/carousel-main/retro.webp');} 
                    &.zipp      {@include backgroundImage('../images/carousel-main/zipp.webp');} 
                    &.cpred     {@include backgroundImage('../images/carousel-main/cpred.webp');} 
                    &.moses     {@include backgroundImage('../images/carousel-main/moses.jpg');} 

                    a {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        display: block;
                    }
                }
            }
        }
    }
}